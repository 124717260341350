
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class OneColumn extends Vue {
    // Member:

    // Properties:
    @Prop({required: false})
    public horizontalPadding?: string;

    @Prop({required: false})
    public verticalPadding?: string;

    @Prop({required: false, default: 'center'})
    public horizontalOrientation?: 'left' | 'center' | 'right' | 'stretch';

    @Prop({required: false, default: 'center'})
    public verticalOrientation?: 'top' | 'center' | 'bottom' | 'stretch';

    // Getter:
    
    // Component Lifecycle Methods:
    
    // Methods:

}
