







import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Chart, { KeyValueData, Series } from '@/components/Statistics/Chart.vue';

interface Statistic {
    measures: {
        [category: string]: {
            [key: string]: number;
        };
    };
}

@Component({
    components: {
        Chart
    }
})
export default class BrandsByNiceClassBarChart extends Vue {
    // Member:
    public statistic = new BusyObject<Statistic>();

    // Properties:
    @Prop({required: false})
    public url?: string;

    @Prop({required: false})
    public data?: any;

    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    @Prop({required: false})
    public showKeysOnly?: boolean;

    // Watcher
    @Watch('url')
    public onUrlChanged() {
        this.loadStatistic();
    }

    @Watch('data')
    public onDataChanged() {
        this.loadStatistic();
    }

    // Getter:
    public get series(): Series[] {
        const result: Series[] = [];

        try {
            const niceClasses = this.statistic.object?.measures['niceClass'];

            if(!niceClasses) {
                return result;
            }

            const series = new Series('Trademarks by offices');

            for(const key in niceClasses) {
                let label: string;

                if(this.showKeysOnly) {
                    label = key;
                } else {
                    const masterDataItem = this.$store.getters.getNiceClass(key);
                    label = masterDataItem?.shortDescription ? `${key} - ${ masterDataItem.shortDescription }` : key;
                }

                series.add({key: label, value: niceClasses[key]} as KeyValueData);
            }

            result.push(series);
        }
        catch(error) {
            console.log(error);
        }

        return result;
    }

    // Component Lifecycle Methods:
    public mounted() {
        this.loadStatistic();
    }

    // Methods:
    public async loadStatistic() {
        await this.statistic.load(async () => {
            if(this.data) 
                return this.data as Statistic;
            else if(this.url) 
                return await this.$util.getData(this.url);
            else 
                return null;
        });
    }
}
